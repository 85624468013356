
import { mapActions } from "vuex";
import filters from "~/mixins/filters";
import cardClick from "~/mixins/cardClick";
export default {
  mixins: [filters, cardClick],
  data() {
    return {
      sliderInit: false
    };
  },
  mounted() {
    // this.$refs.slider_small_desc.addEventListener('contextmenu', event => event.preventDefault());
  },
  props: {
    isForm: {
      type: Boolean,
      default: false
    },
    choose: {
      type: Boolean,
      default: false
    },
    hasButtons: {
      type: Boolean,
      default: true
    },
    slide: {
      type: Boolean,
      default: false
    },
    vertival: {
      type: Boolean,
      default: false
    },
    offer: Object,
    activeFancybox: {
      type: Boolean,
      default: false
    },
    noThumb: {
      type: Boolean,
      default: false
    },
    long:{
      type: Boolean,
      default: true
    }
  },
  methods: {
    ...mapActions({
      closeModal: "modal/modal-main/closeModal"
    }),
    preventLink(e) {
      e.preventDefault();
    }
  },
  computed:{
    isNewCar() {
      return this.offer.run < 100;
    }
  },
  watch: {
    $route() {
      this.closeModal();
    },
    sliderInit(value) {
      value &&
        this.$refs.slider_small_desc.addEventListener("contextmenu", event =>
          event.preventDefault()
        );
    }
  }
};

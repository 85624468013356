
import filters from "~/mixins/filters";

export default {
	data() {
		return {
			gearbox: {
				'mechanical': 'МКПП',
				'cvt': 'Вариатор',
				'robot': 'Робот',
				'automatic': 'АКПП',
			}
		}
	},
	mixins: [filters],
	props: {
		isForm: {
			type: Boolean,
			default: false
		},
		offer: {
			type: Object,
			default: () => {
			}
		},
		small: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		currentGerabox() {
			// return this.gearbox[this.offer.gearbox.name] || this.offer.gearbox.title
			return this.offer.gearbox.title_short_rus
		}
	}
}


import filters from "~/mixins/filters";
// import { mapActions, mapMutations } from "vuex";

export default {
  mixins: [filters],
  computed: {
    generation(){
      const x = this.offer.generation.title || this.offer.generation.name
      // return x.split(' ').length >1? `<br>${x}`:` ${x}`
      return `<br>${x}`

    },
    currentCategory() {
      return this.offer.category_enum;
    },
    currentMark() {
      return this.offer.mark.slug;
    },
    currentFolder() {
      return this.offer.folder.slug;
    },
    currentId() {
      return this.offer.external_id;
    },
  },
  props: {
    isForm: {
      type: Boolean,
      default: false,
    },
    url: String,
    small: {
      type: Boolean,
      default: false,
    },
    offer: {
      type: Object,
      default: () => { },
    },
  },
};


import { mapGetters } from "vuex"
import filters from "~/mixins/filters"

export default {
	mixins: [filters],
	props: {
		isForm: {
			type: Boolean,
			default: false
		},
		vin: String,
		price: Number,
		minicard:{
			type: Boolean,
			default: false
		},
		priceOld:{
			type: String,
			default: ''
		}
	},
  computed: {
    ...mapGetters({
      settings: "settings/settings/settings",
    }),
  },
}
